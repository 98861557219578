import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'imageUrl',
})
export class ImageUrlPipe implements PipeTransform {
  transform(imageId: string): string {
    const newUrl = `${environment.api_url}/api/files/${imageId}/serve?X-TenantID=sunnyhurst`;
    return newUrl;
  }
}
