import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MessageCountData} from '../../pages/messages/message.count.data';
import {PagedResponse} from '../data/paged.response';
import {PrivateMessage} from '../../pages/messages/private.message';

@Injectable()
export class MessageService {

  constructor(private httpClient: HttpClient) {
  }

  getMessageCount() {
    return this.httpClient.get<MessageCountData>(`${environment.api_url}/api/messages/count`);
  }

  getUserMessages() {
    return this.httpClient.get<PagedResponse<PrivateMessage>>(`${environment.api_url}/api/messages`);
  }

  newMessage(createMessage: any) {
    return this.httpClient.post<PrivateMessage>(`${environment.api_url}/api/messages`, createMessage);
  }

  findById(id: string) {
    return this.httpClient.get<PrivateMessage>(`${environment.api_url}/api/messages/byid/${id}`);
  }

  getSentUserMessages() {
    return this.httpClient.get<PagedResponse<PrivateMessage>>(`${environment.api_url}/api/messages?sent=true`);
  }

  deleteMessage(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/messages/byid/${id}`);
  }

  markAsRead(id: string) {
    return this.httpClient.put<PrivateMessage>(`${environment.api_url}/api/messages/byid/${id}`, {
      read: true,
    });
  }

  markAsUnRead(id: string) {
    return this.httpClient.put<PrivateMessage>(`${environment.api_url}/api/messages/byid/${id}`, {
      read: false,
    });
  }
}
