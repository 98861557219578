import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PagedResponse } from '../data/paged.response';
import { ServiceVacate } from '../data/service.vacate';
import { Resident } from '../../pages/resident/resident';
import { User } from '../data/users';
import { Vacate } from '../../pages/vacates/vacate';

@Injectable()
export class VacateService {

  constructor(private httpClient: HttpClient) {
  }

public getAllResidents(date?) {
  if (date) {
    return this.httpClient.get<PagedResponse<Resident>>(`${environment.api_url}/api/residents`, {
      params: new HttpParams().set('date', date)
    });
  }
  else {
    return this.httpClient.get<PagedResponse<Resident>>(`${environment.api_url}/api/residents`);
  }
}

public getUserInfo() {
  return this.httpClient.get<User>(`${environment.api_url}/api/user-info`);
}

  newVacate(createPayload: any) {
    return this.httpClient.post<ServiceVacate>(`${environment.api_url}/api/vacate-form`, createPayload);
  }

  updateVacate(id: string, updatePayload: any) {
    return this.httpClient.put<ServiceVacate>(`${environment.api_url}/api/vacate-form/${id}`, updatePayload);
  }

  findByResId(residentId: any) {
    return this.httpClient.get<PagedResponse<ServiceVacate>>(`${environment.api_url}/api/vacate-form/${residentId}`);
  }

  findVacateAll() {
    return this.httpClient.get<PagedResponse<ServiceVacate>>(`${environment.api_url}/api/vacate-form`);
  }
  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/vacate-form/${id}`);
  }

  // sendEmail(payload){
  //   return this.httpClient.post<PagedResponse<ServiceVacate>>(`${environment.api_url}/api/send-letters-email`, payload);
  // }

}

