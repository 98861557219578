import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { ProspectiveResident, ProspectiveResidentWithId } from '../data/prospective-resident';

@Injectable()
export class ProspectiveResidentService {
    constructor(private httpClient: HttpClient) {
    }
    public getAllResidentsProspective() {
        return this.httpClient.get<ProspectiveResidentWithId>(`${environment.api_url}/api/prospective`);
      }

      public saveProspective(dirtyResident) {
        return this.httpClient.post<ProspectiveResident>(`${environment.api_url}/api/prospective`, dirtyResident);
      }

      findByIdProspective(id: string) {
        return this.httpClient.get<ProspectiveResident>(`${environment.api_url}/api/prospective/${id}`);
      }

      updateResidentProspective(id: string, dirtyFormValues: any) {
        return this.httpClient.put(`${environment.api_url}/api/prospective/${id}`, dirtyFormValues);
      }

      deleteProspective(id: string) {
        return this.httpClient.delete(`${environment.api_url}/api/prospective/${id}`);

      }
}
