import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbTokenStorage } from '@nebular/auth';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { AppFile } from '../data/app.file';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {

  constructor(private httpClient: HttpClient, private tokenLocalStorage: NbTokenStorage, private toastrService: ToastrService) { }

  getFile(id: any) {

    return this.httpClient.get(`${environment.api_url}/api/files/${id}/serve?X-TenantID=${environment.tenant_id}`);

  }

  upload(purpose, fileInput) {
    if (!purpose) {
      this.toastrService.error('Purpose code is not specified.');
      return;
    }
    const fileData = <File>fileInput.target.files[0];
    const formData = new FormData();

    formData.append('file', fileData);
   return this.httpClient.post<AppFile>(`${environment.api_url}/api/files?purpose=${purpose}`, formData, {
      headers: {
        'Authorization': 'Bearer ' + this.tokenLocalStorage.get().getValue(),
        'X-Tenantid': `${environment.tenant_id}`,
      },
      reportProgress: true,
      observe: 'events',
    });
  }
  deleteFile(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/files/${id}`);
  }
}
