
<div class="containerr">


    <h4 class="nosubs">Payment successful!</h4>
    <p class="sp">Please Click below to continue</p>
    <button style="width: 120px;"  class="btn w-40 button__text"
    (click)="onClickPayment()">continue</button>

    <div>
        <img src="../../../../assets/images/srs_wht.png"  alt="Logo" id="logoImage">
    </div>
</div>