import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Room} from '../data/room';
import {PagedResponse} from '../data/paged.response';
import { Observable } from 'rxjs';

@Injectable()
export class RoomService {
  constructor(private httpClient: HttpClient) {
  }

  findAllRoom() {
    return this.httpClient.get<PagedResponse<Room>>(`${environment.api_url}/api/rooms`);
  }

  findById(id: string): Observable<Room> {
    return this.httpClient.get<Room>(`${environment.api_url}/api/rooms/${id}`);
  }

  newRoom(createRoomInput) {
    return this.httpClient.post<Room>(`${environment.api_url}/api/rooms`, createRoomInput);
  }

  newBedForRoom(roomId: string, createBedInput) {
    return this.httpClient.post<Room>(`${environment.api_url}/api/rooms/${roomId}/beds`, createBedInput);
  }

  deleteBedFromRoom(roomId: string, bedId: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/rooms/${roomId}/beds/${bedId}`);
  }

  deleteRoom(roomId: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/rooms/${roomId}`);
  }

  findBedsForRoom(roomId: string) {
    return this.httpClient.get<Room>(`${environment.api_url}/api/rooms/${roomId}/beds`);
  }
}
