import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Address} from '../data/address';

@Injectable()
export class AddressService {

  constructor(private httpClient: HttpClient) {
  }

  create(value: any) {
   return this.httpClient.post<Address>(`${environment.api_url}/api/addresses`, value);
  }

  findById(addressId: string) {
    return this.httpClient.get<Address>(`${environment.api_url}/api/addresses/${addressId}`);
  }

  update(addressId: string, value: any) {
    return this.httpClient.put<Address>(`${environment.api_url}/api/addresses/${addressId}`, value);
  }
}
