import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Medication } from '../../pages/medication/medication';
import { PagedResponse } from '../data/paged.response';

@Injectable()
export class MedicationService {

  constructor(private httpClient: HttpClient) {
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Medication>>(`${environment.api_url}/api/medications`);
  }

  deleteMedication(id: any) {
    return this.httpClient.delete(`${environment.api_url}/api/medications/${id}`);
  }
  
  newMedication(value: any) {
    return this.httpClient.post<Medication>(`${environment.api_url}/api/medications`, value);
  }
  
  updateMedication(id: string, value: any) {
    return this.httpClient.put<Medication>(`${environment.api_url}/api/medications/${id}`, value);
  }
  
  findById(id: string) {
    return this.httpClient.get<Medication>(`${environment.api_url}/api/medications/${id}`);
  }
  
  findByResidentId(residentId: string) {
    return this.httpClient.get<PagedResponse<Medication>>(`${environment.api_url}/api/medications?residentId=${residentId}`);
  }
  
  saveMedicationadminister(payload: any) {
    return this.httpClient.post(`${environment.api_url}/api/medication-history`, payload);
  }
  getMedicationHistory(medicationHistoryParam) {
    const params = new HttpParams({ fromObject: medicationHistoryParam });
    return this.httpClient.get(`${environment.api_url}/api/medication-history/sign-history`, { params });
  }
  getAllPackedMedicationHistory(medicationHistoryParam) {
    const params = new HttpParams({ fromObject: medicationHistoryParam });
    return this.httpClient.get(`${environment.api_url}/api/medication-history/sign-all-history`, { params });
  }
  
  updateMedicationAdminister(id:string,payload){
    return this.httpClient.put<Medication>(`${environment.api_url}/api/medication-history/${id}`, payload);
  }
  
  deleteMedicationAdministeration(id:string){
    return this.httpClient.delete(`${environment.api_url}/api/medication-history/${id}`);
  }
}
