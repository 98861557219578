import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CleanupService {
  constructor() {


    // this.setupBeforeUnloadListener();
  }

  public setupBeforeUnloadListener() {
    window.addEventListener('unload', () => {
      this.cleanup();
    });
  }

   cleanup() {
    // Perform cleanup actions, e.g., remove auth_token
    // localStorage.removeItem('auth_app_token');
    // Additional cleanup logic here
  }
}
