import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionService } from '../../@core/services/subscription.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ngx-stripe-success',
  templateUrl: './stripe-success.component.html',
  styleUrls: ['./stripe-success.component.scss'],
})
export class StripeSuccessComponent implements OnInit, OnDestroy {
  orgId: any;
  public createSubPayload: any = {};
  clientReferenceId: any;
  sessionId: any;
  successPayload: any = {};
  constructor(private router: Router, private subsService: SubscriptionService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.subsService.setRouteInfo();
    document.getElementById('nb-global-spinner').style.display = 'none';

    const plan = localStorage.getItem('plan');
    this.createSubPayload.clientReferenceId = this.orgId;
    this.route.queryParams.subscribe(params => {
      // Access the parameters
      this.clientReferenceId = params['client_reference_id'];
      this.sessionId = params['sessionId'];


      this.successPayload.clientReferenceId = this.clientReferenceId;
      this.successPayload.sessionId = this.sessionId;
      this.successPayload.plan = plan;
      this.subsService.onSuceesPayment(this.successPayload).subscribe((res) => {


      });
      // Perform any other actions with the parameters
    });


  }

  async onClickPayment(){

    // if(localStorage.getItem('isRenewPath')=='true'){
    this.subsService.setRouteInfo();
    try {
      const res = await this.subsService.subscriptionStatus().toPromise();
      const status = res['status'];
      const currentPeriodEnd = res['currentPeriodEnd'];
      const currentDate = new Date();
      const renewalDate = new Date(currentPeriodEnd * 1000);

      if (status === 'active') {

        // Redirect to pages/dashboard if the status is active
        this.router.navigate(['/pages/dashboard']);
        return true;
      } else if (status === null) {
        this.router.navigate(['/subscribe']);
        return false;
        // Redirect to subscribe path for other statuses

      } else if (renewalDate < currentDate) {
        // Navigate to renew path if renewal is required
        this.router.navigate(['/subscribe/renew']);
        return false;
      }
    } catch (error) {
      // Handle the error and redirect to subscribe path
      this.router.navigate(['/auth/login']);
    }

    // }
    // else {

    //   this.router.navigateByUrl('/subscribe/organizationdetails')
    // }

  }

  ngOnDestroy() {
    localStorage.removeItem('AuthToken');
  }
}
