import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ResidentTransfer} from '../data/resident.transfer';
import {PagedResponse} from '../data/paged.response';

@Injectable()
export class ResidentTransferServices {
  constructor(private httpClient: HttpClient) {
  }

  findById(id: string) {
    return this.httpClient.get<ResidentTransfer>(`${environment.api_url}/api/transfers/${id}`);
  }

  newResidentTransfer(value: any) {
    return this.httpClient.post<ResidentTransfer>(`${environment.api_url}/api/transfers`, value);
  }

  updateResidentTransfer(id: string, value: any) {
    return this.httpClient.put<ResidentTransfer>(`${environment.api_url}/api/transfers/${id}`, value);
  }

  findByResidentId(residentId: string) {
    return this.httpClient.get<PagedResponse<ResidentTransfer>>(`${environment.api_url}/api/transfers?residentId=${residentId}`);
  }

  findAll() {
    return this.httpClient.get<PagedResponse<ResidentTransfer>>(`${environment.api_url}/api/transfers?`);
  }
  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/transfers/${id}`);

  }
  transferHandover(value) {
    return this.httpClient.post<any>(`${environment.api_url}/api/resident-transfer-handovers`, value);
  }
  UpdatetransferHandover(id, value) {
    return this.httpClient.put<any>(`${environment.api_url}/api/resident-transfer-handovers/${id}`, value);
  }
}
