import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Booking} from '../../pages/booking/booking';
import {PagedResponse} from '../data/paged.response';

@Injectable()
export class BookingService {

  constructor(private httpClient: HttpClient) {
  }

  findById(id: string) {
    return this.httpClient.get<Booking>(`${environment.api_url}/api/bookings/${id}`);
  }

  newBooking(value: any) {
    return this.httpClient.post<Booking>(`${environment.api_url}/api/bookings`, value);
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Booking>>(`${environment.api_url}/api/bookings`);
  }

  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/bookings/${id}`);
  }

  updateBooking(id: string, value: any) {
    return this.httpClient.put(`${environment.api_url}/api/bookings/${id}`, value);
  }
  findByClientName(name: string) {
    return this.httpClient.get<PagedResponse<Booking>>(`${environment.api_url}/api/bookings?clientName=${name}`);
  }
}
