
 <div class="containerr">


     <h4 class="nosubs">No Subscription Found! </h4>
    <p class="sp">Please click below to purchase & setup SRSPro software for the first time </p>
    <button style="width: 120px;"  class="btn   w-40 button__text"
    (click)="onClickPayment()">Subscribe</button> 
    
       <div  >

        <img src="../../../../assets/images/srs_wht.png"  alt="Logo" id="logoImage">
    </div> 
</div>