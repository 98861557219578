import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Contact} from '../data/contact';
import {PagedResponse} from '../data/paged.response';

@Injectable()
export class ContactService {

  constructor(private httpClient: HttpClient) {
  }

  create(value: any) {
   return this.httpClient.post<Contact>(`${environment.api_url}/api/contacts`, value);
  }

  findById(contactId: string) {
    return this.httpClient.get<Contact>(`${environment.api_url}/api/contacts/${contactId}`);
  }

  update(contactId: string, value: any) {
    return this.httpClient.put<Contact>(`${environment.api_url}/api/contacts/${contactId}`, value);
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Contact>>(`${environment.api_url}/api/contacts`);
  }

  deleteContact(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/contacts/${id}`);
  }
  search(qs) {
    return this.httpClient.get<PagedResponse<Contact>>(`${environment.api_url}/api/contacts?name=${qs}`);
  }
}
