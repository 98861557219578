import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { CleanupService } from '../../@core/services/cleanup.service';
import { SubscriptionService } from '../../@core/services/subscription.service';

@Component({
  selector: 'ngx-renew',
  templateUrl: './renew.component.html',
  styleUrls: ['./renew.component.scss'],
})
export class RenewComponent implements OnInit {
  authTokenStorage: any = {};
  constructor(private router: Router,
    // private cleanupservice: CleanupService,
    private subsservice: SubscriptionService) { }

  ngOnInit(): void {
  
    document.getElementById('nb-global-spinner').style.display = 'none';

    localStorage.setItem('isRenewPath', 'true');
    const authToken = localStorage.getItem('auth_app_token');
    this.authTokenStorage = JSON.parse(authToken);



    localStorage.setItem('AuthToken', this.authTokenStorage.value);
    // Retrieve an item from local storage
    // const token = localStorage.getItem('auth_app_token');



  }
  onClickPayment() {
    this.router.navigate(['/subscribe/terms-and-condition']);
  }
}
