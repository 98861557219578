import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';
import {PerfReview} from '../data/perf.review';

@Injectable()
export class PerformanceReviewService {
  constructor(private httpClient: HttpClient) {
  }


  newReviewForUser(id: string, reviewPayload: any) {
    if (!reviewPayload) {
      reviewPayload  = {};
    }
    reviewPayload.userId = id;
    return this.httpClient.post<any>(`${environment.api_url}/api/pro-dev`, reviewPayload);
  }

  listReviewForUser(userId: string) {
    return this.httpClient.get<PagedResponse<PerfReview>>(`${environment.api_url}/api/pro-dev?userId=${userId}`);
  }

  delete(item) {
    return this.httpClient.delete<PagedResponse<PerfReview>>(`${environment.api_url}/api/pro-dev/${item}`);
  }
}
