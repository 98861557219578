import { Component, OnDestroy, OnInit } from '@angular/core';
// import { SubscriptionService } from '../../@core/services/subscription.service';
// import { CleanupService } from '../../@core/services/cleanup.service';

@Component({
  selector: 'ngx-addsubscription',
  templateUrl: './addsubscription.component.html',
  styleUrls: ['./addsubscription.component.scss'],
})
export class AddsubscriptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.getElementById('nb-global-spinner').style.display = 'none';
  }

}
