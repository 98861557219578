import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PaymentMethod} from '../../pages/settings/payment-method/payment.method';
import {Observable} from 'rxjs';
import {PagedResponse} from '../data/paged.response';
import {PaymentFrequency} from '../../pages/settings/payment-frequencies/payment-frequency';

@Injectable()
export class SettingsService {
  constructor(private httpClient: HttpClient) {
  }

  newMethod(value: any): Observable<PaymentMethod> {
    return this.httpClient.post<PaymentMethod>(`${environment.api_url}/api/payment-methods`, value);
  }

  allPaymentMethods(): Observable<PagedResponse<PaymentMethod>> {
    return this.httpClient.get<PagedResponse<PaymentMethod>>(`${environment.api_url}/api/payment-methods`);
  }
  deletePaymentMethod(id: any) {
    return this.httpClient.delete<PaymentFrequency>(`${environment.api_url}/api/payment-methods/${id}`);
  }

  allPaymentFrequencies() {
    return this.httpClient.get<PagedResponse<PaymentFrequency>>(`${environment.api_url}/api/payment-frequencies`);
  }

  newFrequency(value: any) {
    return this.httpClient.post<PaymentFrequency>(`${environment.api_url}/api/payment-frequencies`, value);
  }
  deleteFrequency(id: any) {
    return this.httpClient.delete<PaymentFrequency>(`${environment.api_url}/api/payment-frequencies/${id}`);
  }
}
