import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Resident } from '../../pages/resident/resident';
import { PagedResponse } from '../data/paged.response';
import * as qs from 'querystring';
import { HttpHeaders } from '@angular/common/http';
import { NbTokenStorage } from '@nebular/auth';

@Injectable()
export class ResidentService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
  };

  constructor(private httpClient: HttpClient, private tokenLocalStorage: NbTokenStorage) {
  }


  public getAllResidents(date?) {
    if (date) {
      return this.httpClient.get<PagedResponse<Resident>>(`${environment.api_url}/api/residents`, {
        params: new HttpParams().set('date', date)
      });
    }
    else {
      return this.httpClient.get<PagedResponse<Resident>>(`${environment.api_url}/api/residents`);
    }
  }

  public save(dirtyResident) {
    return this.httpClient.post<Resident>(`${environment.api_url}/api/residents`, dirtyResident);
  }


  findById(id: string, date?) {
    if (date) {
      const params = new HttpParams({ fromObject: { date } });
      return this.httpClient.get<Resident>(`${environment.api_url}/api/residents/${id}`, { params });
    }
    else {
      return this.httpClient.get<Resident>(`${environment.api_url}/api/residents/${id}`);
    }
  }

  updateResident(id: string, dirtyFormValues: any) {
    return this.httpClient.put(`${environment.api_url}/api/residents/${id}`, dirtyFormValues);
  }

  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/residents/${id}`);

  }

  getResidentChartData(chartType: string, residentId: string) {
    return this.httpClient.get<PagedResponse<any>>(`${environment.api_url}/api/residents/${residentId}/chart/${chartType}`);
  }

  residentNewChartData(chartType: string, residentId: string, value: any) {
    return this.httpClient.post<PagedResponse<any>>(`${environment.api_url}/api/residents/${residentId}/chart/${chartType}`, value);
  }

  getResidentChartDataById(chartType: string, residentId: string, woundId: string) {
    return this.httpClient.get<any>(`${environment.api_url}/api/residents/${residentId}/chart/${chartType}/${woundId}`);
  }

  searchResident(param: any) {
    return this.httpClient.get<PagedResponse<Resident>>(`${environment.api_url}/api/residents?${qs.encode(param)}`);
  }

  residentUpdateChartData(chartType: string, residentId: string, chartId: string, value: any) {
    return this.httpClient.put<PagedResponse<any>>(`${environment.api_url}/api/residents/${residentId}/chart/${chartType}/${chartId}`, value);
  }

  residentDeleteChartData(chartType: string, residentId: string, chartId: string) {
    return this.httpClient.delete(`${environment.api_url}/api/residents/${residentId}/chart/${chartType}/${chartId}`);
  }

  deleteReview(id, rId) {
    return this.httpClient.delete(`${environment.api_url}/api/support-plans/${rId}/reviews/${id}`);
  }

  woundAssessment(residentId, chartId, input) {
    return this.httpClient.post<PagedResponse<any>>(`${environment.api_url}/api/residents/${residentId}/chart/wound/${chartId}/wound-assessment`, input);
  }
  WoundAssessementDelete(id, chartID, assessmentID) {
    return this.httpClient.delete(`${environment.api_url}/api/residents/${id}/chart/wound/${chartID}/wound-assessment/${assessmentID}`);
  }
  WoundDiagram(id, chartId, input: FormData) {
    return this.httpClient.post<any>(`${environment.api_url}/api/residents/${id}/chart/wound/${chartId}/wound-diagram`,
      input);
  }
  getWoundDiagram(id, chartID) {
    return this.httpClient.get<any>(`${environment.api_url}/api/residents/${id}/chart/wound/${chartID}/wound-diagram`);
  }
  generateBalanceSheet(input: any) {
    return this.httpClient.post<PagedResponse<any>>(`${environment.api_url}/api/finance-sheets`, input);
  }
  findByFinanceResidentId(id: string) {
    return this.httpClient.get<any>(`${environment.api_url}/api/finance-sheets?residentId=${id}`);
  }
  createNewEntryOfFinance(id: string, input: any) {
    return this.httpClient.post<PagedResponse<any>>(`${environment.api_url}/api/finance-sheets/${id}/entries`, input);
  }
  findByetriesID(id: string, entryId: string) {
    return this.httpClient.get<any>(`${environment.api_url}/api/finance-sheets/${id}/entries/${entryId}`);
  }
  UpdateEntryOfFinance(id: string, entryId: string, input: any) {
    return this.httpClient.put<PagedResponse<any>>(`${environment.api_url}/api/finance-sheets/${id}/entries/${entryId}`, input);
  }
  deleteResidentGF(resId, docId) {
    return this.httpClient.delete<PagedResponse<any>>(`${environment.api_url}/api/residents/${resId}/general-files/${docId}`);
  }
  deleteResidentMD(resId, docId) {
    return this.httpClient.delete<PagedResponse<any>>(`${environment.api_url}/api/residents/${resId}/medical-files/${docId}`);
  }

  addResidentCareSummary(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.tokenLocalStorage.get().getValue(),
        'Content-Type': 'application/json'
      })
    };
    // httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.tokenLocalStorage.get().getValue());
    // httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    return this.httpClient.post<any>(`${environment.api_url}/api/residents?X-TenantID=${environment.tenant_id}`, data, httpOptions);
  }
}
