import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()

export class SupportPlanTemplateService {

    constructor(private Httpclient: HttpClient) {
    }
    newtemplate(formValue) {
        return this.Httpclient.post(`${environment.api_url}/api/support-plan-templates`, formValue);

    }
    updatetemplate(utId: number , value: any) {
        return this.Httpclient.put(`${environment.api_url}/api/support-plan-templates/${utId}`, value);

    }
    findbyid(id: any) {
        return this.Httpclient.get(`${environment.api_url}/api/support-plan-templates/${id}`);
    }

}
