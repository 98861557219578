import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';
import {Complaint} from '../../pages/complaint/complaint';
import {UpdateComplaintInput} from '../../pages/complaint/update.complaint.input';

@Injectable()
export class ComplaintService {

  constructor(private httpClient: HttpClient) {
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Complaint>>(`${environment.api_url}/api/complaints`);
  }

  findById(id: string) {
    return this.httpClient.get<Complaint>(`${environment.api_url}/api/complaints/${id}`);
  }

  newComplaint(value: any) {
    return this.httpClient.post<Complaint>(`${environment.api_url}/api/complaints`, value);
  }

  updateComplaint(id: string, value: UpdateComplaintInput) {
    return this.httpClient.put<Complaint>(`${environment.api_url}/api/complaints/${id}`, value);
  }

  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/complaints/${id}`);
  }
  findByComplaint(qs) {
    return this.httpClient.get<PagedResponse<Complaint>>(`${environment.api_url}/api/complaints?complainant=${qs}`);
  }
  complaintHandover(value: any) {

    return this.httpClient.post<any>(`${environment.api_url}/api/complaint-handover`, value);
  }
  complaintUpdateHandover(id, value: any) {

    return this.httpClient.put<any>(`${environment.api_url}/api/complaint-handover/${id}`, value);
  }
  deleteHandover(id: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/complaint-handover/${id}`);
  }
}
