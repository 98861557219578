import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {OrganizationModel} from '../../pages/organization/organization.model';
import {CreateOrganizationInput} from '../../pages/organization/create-organization-input';

@Injectable()
export class OrganizationService {
  constructor(private httpClient: HttpClient) {
  }

  /*addOrg(formData: FormData) {
    return this.httpClient.post<OrganizationModel>(`${environment.api_url}/api/organization`, formData);
  }

  updateOrg(orgId: string, formData: FormData) {
    return this.httpClient.put<OrganizationModel>(`${environment.api_url}/api/organization/${orgId}`, formData);
  }*/

  findOrg() {
    return this.httpClient.get<OrganizationModel>(`${environment.api_url}/api/organization`);
  }
  findCurrentOrg() {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('AuthToken')}`,
    };
    return this.httpClient.get<OrganizationModel>(`${environment.api_url}/api/organization`, { headers });
  }

  createOrg(createPayload: CreateOrganizationInput) {
    return this.httpClient.post<OrganizationModel>(`${environment.api_url}/api/organization`, createPayload);
  }

  updateOrg(orgId: string, value: any) {
    return this.httpClient.put<OrganizationModel>(`${environment.api_url}/api/organization/${orgId}`, value);
  }
  updateCurrentOrg(orgId: string, value: any) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('AuthToken')}`,
    };
    return this.httpClient.put<OrganizationModel>(`${environment.api_url}/api/organization/${orgId}`, value, {headers});
  }
  deleteorg(id: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/organization/${id}`);
  }

}
