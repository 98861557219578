import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SupportPlan} from '../data/support.plan';
import {PagedResponse} from '../data/paged.response';
import {SupportPlanReminder} from '../data/support.plan.reminder';

@Injectable()
export class SupportPlanService {
  OSPData: any;
  constructor(private httpClient: HttpClient) {
  }

  findById(id: string) {
    return this.httpClient.get<SupportPlan>(`${environment.api_url}/api/support-plans/${id}`);
  }

  newPlan(formValue: any) {
    return this.httpClient.post(`${environment.api_url}/api/support-plans`, formValue);
  }

  updatePlan(spId: string, value: any) {
    return this.httpClient.put(`${environment.api_url}/api/support-plans/${spId}`, value);
  }

  findByResidentId(residentId: string) {
    return this.httpClient.get<PagedResponse<SupportPlan>>(`${environment.api_url}/api/support-plans?residentId=${residentId}`);
  }

  getSupportPlanReminders() {
    return this.httpClient.get<PagedResponse<SupportPlanReminder>>(`${environment.api_url}/api/support-plan-reminders`);
  }

  newPlanReview(spId: string, payload: any) {
    return this.httpClient.post<SupportPlan>(`${environment.api_url}/api/support-plans/${spId}/reviews`, payload);
  }

  findAll(paramPayload?) {
    if(paramPayload){
      let url = `${environment.api_url}/api/support-plans?page=${paramPayload.page}&size=${paramPayload.size}&type=${paramPayload.type}&sortBy=${paramPayload.sortBy}&sortDirection=${paramPayload.sortDirection}`
      if(paramPayload.isDischarged) url += `&isDischarged=${paramPayload.isDischarged}`
      return this.httpClient.get<PagedResponse<SupportPlan>>(url);
    }
    return this.httpClient.get<PagedResponse<SupportPlan>>(`${environment.api_url}/api/support-plans`);
  }

  deletePlan(id: any) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/support-plans/${id}`);
  }

  findRevisionsById(spId: string) {
    return this.httpClient.get<any[]>(`${environment.api_url}/api/support-plans/${spId}/revisions`);
  }
  findReviewsById(spId: string) {
    return this.httpClient.get<any[]>(`${environment.api_url}/api/support-plans/${spId}/reviews`);
  }
  SupportPlanReminder() {
    return this.httpClient.get<PagedResponse<any>>(`${environment.api_url}/api/support-plan-reminders`);
  }
  getOSPHistory(id, rid) {
    return this.httpClient.get(`${environment.api_url}/api/support-plans/${id}/reviews/${rid}`);

  }
  updateHistory(id, rid, payload) {
    return this.httpClient.put(`${environment.api_url}/api/support-plans/${id}/reviews/${rid}`, payload);
  }
}
