import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {StripePublicKey} from '../data/stripe.public.key';
import {StripeChargePaymentResponse} from '../data/stripe.charge.payment.response';

@Injectable()
export class SubscriptionService {
  userToken: any;
  routetoDash: boolean = false;
  orgId: any;
  httpOptions: any;
  constructor(private httpClient: HttpClient) {
     this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }
  setRouteInfo() {
   this.routetoDash = true;
  }
onSuceesPayment(Payload: any) {
  return this.httpClient.post(`${environment.api_url}/api/subscription`, Payload);
}

  passRouteInfo() {
   return this.routetoDash;
   this.setfasleInfo();
  }
  setfasleInfo() {
    this.routetoDash = false;
  }
  passToken(token) {
  this.userToken =  token;
  }
  getToken() {
    localStorage.setItem('auth_app_token', this.userToken);
  }

  fetchStripePublicKey() {
    return this.httpClient.get<StripePublicKey>(`${environment.api_url}/api/subscription-info`);
  }

  onCheckoutStripe(Payload: any) {
    return this.httpClient.post(`${environment.api_url}/api/subscription/create-checkout-session`, Payload);
  }
  createSubscription(param: any) {
    return this.httpClient.post(`${environment.api_url}/api/subscription`, param, this.httpOptions);
  }

  createCharge(param: any) {
    return this.httpClient.post<StripeChargePaymentResponse>(`${environment.api_url}/api/subscription/charge`, param);
  }

  subscriptionStatus() {
    return this.httpClient.get(`${environment.api_url}/api/subscription-info/status`);
  }
  deleteSubscription() {
    return this.httpClient.delete(`${environment.api_url}/api/subscription`);
  }

}
