import { Component } from '@angular/core';
import {
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import {LayoutService} from '../../../@core/utils';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>


      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
      <div class="close-icon-bar">
      <a

      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    </div>
      <div class="header-logo-wrap">
            <img src="../../../../assets/images/logo_cutout_wht.png" height="75">
            <a  class="logo" href="/#/pages/dashboard">SRSPro</a>
          </div>
        <ng-content select="nb-menu" ></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <nb-layout-header>
          <ngx-header></ngx-header>
        </nb-layout-header>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  public readonly materialTheme$: Observable<boolean>;
  public constructor(
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,

  ) {
    this.materialTheme$ = this.themeService.onThemeChange()
      .pipe(map(theme => {
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      }));
  }
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
}
