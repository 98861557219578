import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { CleanupService } from '../../@core/services/cleanup.service';
// import { SubscriptionService } from '../../@core/services/subscription.service';
@Component({
  selector: 'ngx-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss'],
})
export class StripePaymentComponent implements OnInit {
  showPlans = 'Small SRS';
  ref: any;
  authTokenStorage: any;
  constructor(private router: Router, private cleanupserice: CleanupService,
    // private subscriptionService: SubscriptionService
  ) {
    // window.addEventListener('beforeunload', this.onBeforeUnload);
  }

  ngOnInit(): void {
    localStorage.setItem('isRenewPath', 'false');

    document.getElementById('nb-global-spinner').style.display = 'none';
    this.cleanupserice.setupBeforeUnloadListener();
    const authToken = localStorage.getItem('auth_app_token');
    this.authTokenStorage = JSON.parse(authToken);

    localStorage.setItem('AuthToken', this.authTokenStorage.value);


  }
  changePlan(value) {
    this.showPlans = value;
    // this.router.navigate('')
    // this.showplanDialogRef.close();
  }


  onClickPayment() {

    this.router.navigateByUrl('/subscribe/terms-and-condition');

  }
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    // // Check if the user is logged in
    // this.cleanupservice.cleanup()
    // Perform cleanup actions, e.g., log out the user
    // localStorage.removeItem('auth_app_token');
    // Additional cleanup logic here

  }


}
