import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[ngxAccessControl]',
})
export class AccessControlDirective {
  @Input('moduleType') moduleType: string;
  @Input('accessType') accessType: string;
  roleId: string;
  accessControls: any;

  constructor(
    private elementRef: ElementRef,
  ) {}

  ngOnInit() {
    this.checkAccess();
  }
  async checkAccess() {
    const accessControls: any = JSON.parse(localStorage.getItem('roles'));
    const module: any = accessControls?.find((access) => {
      if (access.module === this.moduleType) {
        return access.module;
      }
    });

    this.elementRef.nativeElement.style.display = module[this.accessType]
      ? 'block'
      : 'none';
  }
}
