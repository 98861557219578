import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';

@Injectable()
export class ActivityOfEntityService {
  constructor(private httpClient: HttpClient) {
  }

  public search() {
    return this.httpClient.get<PagedResponse<any>>(`${environment.api_url}/api/entity-activities`);
  }
  findByuser(qs) {
    return this.httpClient.get<PagedResponse<any>>(`${environment.api_url}/api/entity-activities?user=${qs}`);
  }
}
