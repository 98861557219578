import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-tcpage',
  templateUrl: './tcpage.component.html',
  styleUrls: ['./tcpage.component.scss'],
})
export class TcpageComponent implements OnInit, OnDestroy {

  constructor(private router: Router) { }

  ngOnInit(): void {
    document.getElementById('nb-global-spinner').style.display = 'none';
  }
  onClickPayment() {
    this.router.navigateByUrl('/subscribe/organizationdetails');
  }
  ngOnDestroy() {
    localStorage.setItem('reloadPage', 'true');
  }

}
