import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SubscribeRoutingModule } from './subscribe-routing.module';
import { AddsubscriptionComponent } from './addsubscription/addsubscription.component';
import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';
import { StripeWindowComponent } from './stripe-window/stripe-window.component';
import { StripeSuccessComponent } from './stripe-success/stripe-success.component';
import { TcpageComponent } from './tcpage/tcpage.component';

import { ReactiveFormsModule } from '@angular/forms';
import { OrgformComponent } from './orgform/orgform.component';
import { RenewComponent } from './renew/renew.component';

import { NbCardModule } from '@nebular/theme';
@NgModule({
  declarations: [
    AddsubscriptionComponent,
    StripePaymentComponent,
    StripeWindowComponent,
    StripeSuccessComponent,
    TcpageComponent,
    OrgformComponent,
    RenewComponent
  ],
  imports: [
    CommonModule,
    SubscribeRoutingModule,
    ReactiveFormsModule,
    NbCardModule,
  ],

  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class SubscribeModule { }
