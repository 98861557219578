import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PagedResponse} from '../data/paged.response';
import {environment} from '../../../environments/environment';


@Injectable()
export class ChartService {

  constructor(private httpClient: HttpClient) {
  }


  findAll(chartType: string) {
    return this.httpClient.get<PagedResponse<any>>(`${environment.api_url}/api/charts?type=${chartType}`);
  }

  newchart(input: any) {
    return this.httpClient.post<any>(`${environment.api_url}/api/charts`, input);
  }

  deleteChart(chartType: string, id: any) {
    return this.httpClient.get<any>(`${environment.api_url}/api/charts/${chartType}/${id}`);
  }

  deleteChartData(type, chartId, rId) {
    return this.httpClient.delete(`${environment.api_url}/api/residents/${rId}/chart/${type}/${chartId}`);
  }

  searchByresident(residentName: any, chartType: any) {

    return this.httpClient.get<PagedResponse<any>>(`${environment.api_url}/api/charts?residentName=${residentName}&type=${chartType}`);
  }
  reminderBowel() {
    return this.httpClient.get<any>(`${environment.api_url}/api/charts/bowel/notifications`);
  }
  reminderWound() {
    return this.httpClient.get<any>(`${environment.api_url}/api/charts/wound/notifications`);
  }

}
