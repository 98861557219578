import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Appointment, AppointmentPurpose} from '../../pages/appointment/appointment';
import {PagedResponse} from '../data/paged.response';
import {CreateAppointmentInput} from '../../pages/appointment/create.appointment.input';
import {UpdateAppointmentInput} from '../../pages/appointment/update.appointment.input';

@Injectable()
export class AppointmentService {

  constructor(private httpClient: HttpClient) {
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Appointment>>(`${environment.api_url}/api/appointments`);
  }
  AppointmentReminder(payload) {
    const numDaysDue = 0;
    const params = new HttpParams().set('numDaysDue', numDaysDue.toString());
    return this.httpClient.post<PagedResponse<any>>(`${environment.api_url}/api/appointments/get-reminders?`, payload, { params });
  }

  findById(id: string) {
    return this.httpClient.get<Appointment>(`${environment.api_url}/api/appointments/${id}`);
  }

  newAppointment(payload: CreateAppointmentInput) {
    return this.httpClient.post<Appointment>(`${environment.api_url}/api/appointments`, payload);
  }

  CheckAppointmentConflict(payload: CreateAppointmentInput) {
    return this.httpClient.post<Appointment>(`${environment.api_url}/api/appointments/appointmentStatus`, payload);
  }

  updateAppointment(id: string, payload: UpdateAppointmentInput) {
    return this.httpClient.put<Appointment>(`${environment.api_url}/api/appointments/${id}`, payload);
  }
  deleteAppointment(id: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/appointments/${id}`);
  }

  findAllPurpose() {
    return this.httpClient.get<PagedResponse<AppointmentPurpose>>(`${environment.api_url}/api/appointment-purposes`);
  }

  findByIdPurpose(id: string) {
    if(id){
      return this.httpClient.get<AppointmentPurpose>(`${environment.api_url}/api/appointment-purposes/${id}`);
    }
  }
  findDueDays() {
    const Payload = {

    };
    return this.httpClient.post<any>(`${environment.api_url}/api/appointments/get-recurring-due-dates`, Payload);
  }

  newAppointmentPurpose(payload: CreateAppointmentInput) {
    return this.httpClient.post<AppointmentPurpose>(`${environment.api_url}/api/appointment-purposes`, payload);
  }

  updateAppointmentPurpose(id: string, payload: UpdateAppointmentInput) {
    return this.httpClient.put<AppointmentPurpose>(`${environment.api_url}/api/appointment-purposes/${id}`, payload);
  }
  deleteAppointmentPurpose(id: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/appointment-purposes/${id}`);
  }
  getSearchByResidentId(id: string) {
    return this.httpClient.get<PagedResponse<Appointment>>(`${environment.api_url}/api/appointments?residentId=${id}`);
  }
  getSearchByDoctortId(id: string) {
    return this.httpClient.get<PagedResponse<Appointment>>(`${environment.api_url}/api/appointments?doctorId=${id}`);
  }
}
