import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class DashboardService {

  constructor(private httpClient: HttpClient) {
  }

  getSummaryData() {
    return this.httpClient.get<any[]>(`${environment.api_url}/api/dashboard/summary`);
  }
}
