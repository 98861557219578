import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';
import {ProgressNote} from '../data/progress.note';

@Injectable()
export class ProgressNoteService {
  constructor(private httpClient: HttpClient) {
  }

  findById(id: string) {
    return this.httpClient.get(`${environment.api_url}/api/resident-progress/${id}`);
  }

  newProgress(value: any) {
    return this.httpClient.post(`${environment.api_url}/api/resident-progress`, value);
  }

  updateProgress(id: string, value: any) {
    return this.httpClient.put(`${environment.api_url}/api/resident-progress/${id}`, value);
  }

  findByResidentId(residentId: string) {
    return this.httpClient.get<PagedResponse<ProgressNote>>(`${environment.api_url}/api/resident-progress?residentId=${residentId}`);
  }

  findLatest() {
    return this.httpClient.get<PagedResponse<ProgressNote>>(`${environment.api_url}/api/resident-progress?latest=true`);
  }

  findAll() {
    return this.httpClient.get<PagedResponse<ProgressNote>>(`${environment.api_url}/api/resident-progress`);
  }
  search(qs) {
    return this.httpClient.get<PagedResponse<ProgressNote>>(`${environment.api_url}/api/resident-progress?residentId=${qs}`);
  }

  deleteProgressNote($event: any) {
    return this.httpClient.delete<PagedResponse<ProgressNote>>(`${environment.api_url}/api/resident-progress/${$event}`);
  }
  progressHandover(value) {
    return this.httpClient.post<any>(`${environment.api_url}/api/resident-progress-handovers`, value);
  }
  progressUpdateHandover(id, value) {
    return this.httpClient.put<any>(`${environment.api_url}/api/resident-progress-handovers/${id}`, value);
  }
  deleteHandover(id: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/resident-progress-handovers/${id}`);
  }

}
