
<div class="containerr">


    <h4 class="nosubs">Your Subscription has Expired!</h4>
    <p class="sp">Please Click Below for Payment Details</p>
    <button style="width: 120px;"  class="btn w-40 button__text"
    (click)="onClickPayment()">Renew</button>

    <div>
        <img src="../../../../assets/images/srs_wht.png"  alt="Logo" id="logoImage">
    </div>
</div>