import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class ForgetPasswordService {

  constructor(private httpClient: HttpClient) {
  }

 resetPasswordVerify(input: any) {
    return this.httpClient.post<any>(`${environment.api_url}/api/password-reset/verify`, input);
 }
 resetPasswordInit(input: any) {
    return this.httpClient.post<any>(`${environment.api_url}/api/password-reset/init`, input);
 }
}
