import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';
import {SupportPlanCategory} from '../data/support.plan.category';

@Injectable()
export class SupportPlanEntryService {
  constructor(private httpClient: HttpClient) {
  }

  findAllOnGoingEntry() {
    return this.httpClient.get<PagedResponse<SupportPlanCategory>>(`${environment.api_url}/api/support-plan-categories`);
  }


  saveOngoingEntryForSupportPlan(residentId: string, spId: string, payload: any) {
    return this.httpClient.post<any>(`${environment.api_url}/api/ongoing-entries`, payload);
  }

  saveInterimEntryForSupportPlan(residentId: string, spId: string, payload: any) {
    return this.httpClient.post<any>(`${environment.api_url}/api/interim-entries`, payload);
  }

  findAllEntriesForSupportPlan(spId: string) {
    return this.httpClient.get<any[]>(`${environment.api_url}/api/support-plans/${spId}`);
  }

  deleteOngoingEntryById(speId: number) {
    return this.httpClient.delete(`${environment.api_url}/api/ongoing-entries/${speId}`);
  }

  deleteInterimEntryById(speId: number) {
    return this.httpClient.delete(`${environment.api_url}/api/interim-entries/${speId}`);
  }

  updateOngoingEntryById(speId: number, payload: any) {
    return this.httpClient.put<any>(`${environment.api_url}/api/ongoing-entries/${speId}`, payload);
  }

  updateInterimEntryById(speId: number, payload: any) {
    return this.httpClient.put<any>(`${environment.api_url}/api/interim-entries/${speId}`, payload);
  }
}
