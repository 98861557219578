import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {NbTokenService, NbTokenStorage} from '@nebular/auth';
import { environment } from '../../../environments/environment';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {

  constructor(private tokenService: NbTokenService,
              private tokenLocalStorage: NbTokenStorage) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.tokenLocalStorage.get() && this.tokenLocalStorage.get().getValue()) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.tokenLocalStorage.get().getValue()) });
    }

    if (!request.headers.has('X-TenantID')) {


      // Add the X-TenantID header with the desired value
      request = request.clone({
        headers: request.headers.set('X-TenantID', `${environment.tenant_id}`),
      });
    }


    if (request.url.includes(`${environment.api_url}/api/files/`)) {

      const newUrl = request.url.replace(`/serve`, `/serve?X-TenantID=${environment.tenant_id}`);
      request = request.clone({ url: newUrl });
    }

    if (!request.headers.has('Content-Type') && !request.url.endsWith('wound-diagram')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    return next.handle(request);
  }

}
