import { Directive, ElementRef, Input } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { RoleService } from '../../@core/services/role.service';

@Directive({
  selector: '[ngxEditAccess]',
})
export class EditAccessDirective {
  @Input('moduleType') moduleType: string;
  @Input('accessType') accessType: string;
  roleId: string;
  accessControls: any;
  constructor(
    private elementRef: ElementRef,
    private roleService: RoleService,
    private nbauth: NbAuthService,
  ) {}

  ngOnInit() {
    // this.elementRef.nativeElement.style.display = "none";
    this.checkAccess();
  }
  async checkAccess() {

    const accessControls: any = JSON.parse(localStorage.getItem('roles'));
    // let roleId = localStorage.getItem('roleId')


    // await this.roleService.findAccess(roleId).toPromise().then(
    //   value => {
    //     this.accessControls = value;
    //   });
    const module: any = accessControls?.find((access) => {
      if (access.module === this.moduleType) {
        return access.module;
      }
    });
    this.elementRef.nativeElement.style.pointerEvents = module[this.accessType]
      ? 'default'
      : 'none';
  }
}
