import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionService } from '../../@core/services/subscription.service';
// import { loadStripe } from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'ngx-stripe-window',
  templateUrl: './stripe-window.component.html',
  styleUrls: ['./stripe-window.component.scss'],
})
export class StripeWindowComponent implements OnInit, AfterViewInit {
  CLIENT_REFERENCE_ID: any = null;
  constructor(private router: Router,
    private subserive: SubscriptionService) { }
  showPlans = 'Small Plan';
  CheckoutPayload: any = {};
  async ngOnInit(): Promise<void> {
    document.getElementById('nb-global-spinner').style.display = 'none';
    // const btn = document.getElementsByClassName('Button PriceColumn-button Button--primary Button--lg');

    const token = localStorage.getItem('auth_app_token');
    this.subserive.passToken(token);



  }
  async changePlan(value) {
    this.showPlans = value;
    this.CheckoutPayload.email = 'Development0261@gmail.com';
    // this.CheckoutPayload.paymentSuccessUrl="http://143.0.0.153:4200/#/subscribe/success"
    this.CheckoutPayload.paymentSuccessUrl = `${environment.server_url}/#/subscribe/success`;
    // this.CheckoutPayload.paymentCancelUrl="http://143.0.0.153:4200/#/subscribe"
    this.CheckoutPayload.paymentCancelUrl = `${environment.server_url}/#/subscribe/organizationdetails`;
    this.CheckoutPayload.plan = this.showPlans;
    this.subserive.onCheckoutStripe(this.CheckoutPayload).subscribe((res) => {

      const response = res as { url: string }; // Type assertion
      const paymentUrl = response.url;


      window.location.href = paymentUrl;
    });
  }
  ngAfterViewInit() {
    // Retrieve the CLIENT_REFERENCE_ID value from local storage
    this.CLIENT_REFERENCE_ID = localStorage.getItem('orgId');


  }


  handlePaymentSuccess(event: any) {
    // Handle the payment success event
    this.router.navigate(['']);

    // Trigger necessary events or actions in your application
  }


}
