import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';
import {Meetings} from '../data/meetings';

@Injectable()
export class MeetingService {

  constructor(private httpClient: HttpClient) {
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Meetings>>(`${environment.api_url}/api/meetings`);
  }

  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/meetings/${id}`);
  }

  findById(id: string) {
    return this.httpClient.get<Meetings>(`${environment.api_url}/api/meetings/${id}`);
  }

  newMeeting(value: any) {
    return this.httpClient.post<Meetings>(`${environment.api_url}/api/meetings`, value);
  }

  updateMeeting(id: string, value: any) {
    return this.httpClient.put<Meetings>(`${environment.api_url}/api/meetings/${id}`, value);
  }
}
