import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TaskCreate } from '../data/task-list-create';

@Injectable({
  providedIn: 'root'
})
export class TaskListService {

  constructor(
    private http:HttpClient
  ) { }

  createTask(payload: TaskCreate){
    return this.http.post(`${environment.api_url}/api/task-list`,payload);
  }

  getAllTasks(){
    return this.http.get(`${environment.api_url}/api/task-list`);
  }
  
  updateTasks(id:string, payload){
    return this.http.put(`${environment.api_url}/api/task-list/${id}`,payload);
  }
  
  deleteTask(id:string){
    return this.http.delete(`${environment.api_url}/api/task-list/${id}`);
  }
  
  getNotifications(type:string){
    return this.http.get(`${environment.api_url}/api/task-list/task-list-notifications?taskListNotificationType=${type}`);

  }
}
