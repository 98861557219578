import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PagedResponse} from '../data/paged.response';
import {environment} from '../../../environments/environment';
import {RosterModel} from '../data/roster.model';

@Injectable()
export class RosterService {
  constructor(private httpClient: HttpClient) {
  }

  findRosterDataForDates(startDate, endDate) {
    return this.httpClient.get<PagedResponse<RosterModel>>(`${environment.api_url}/api/staff-roster?rosterStartDate=${startDate}&rosterEndDate=${endDate}`);
  }

}
