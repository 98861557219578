import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {NbAuthService} from '@nebular/auth';
import {tap} from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: NbAuthService, private router: Router, private stripeService: SubscriptionService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> | boolean | UrlTree {
      // Remove the token from local storage



      this.authService.getToken().subscribe(value => {
      });
      return this.authService.isAuthenticated()
      .pipe(tap(isAuthed => {


        if (!isAuthed) {
          this.router.navigate(['auth/login']);
        }
      }));
  }

}
