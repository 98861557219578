import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';
import {NbRoleProvider, NbSecurityModule} from '@nebular/security';
import {of as observableOf} from 'rxjs';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {AnalyticsService, LayoutService} from './utils';
import {UserService} from './services/user.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {JwtTokenInterceptor} from './services/jwt.token.interceptor';
import {AuthGuard} from './services/auth.guard';
import {ResidentService} from './services/resident.service';
import {environment} from '../../environments/environment';
import {OrganizationService} from './services/organization.service';
import {SettingsService} from './services/settings.service';
import {AgreementService} from './services/agreement.service';
import {BookingService} from './services/booking.service';
import {AppointmentService} from './services/appointment.service';
import {ComplaintService} from './services/complaint.service';
import {DashboardService} from './services/dashboard.service';
import {DoctorService} from './services/doctor.service';
import {HandoverService} from './services/handover.service';
import {IncidentService} from './services/incident.service';
import {InvoiceService} from './services/invoice.service';
import {MessageService} from './services/message.service';
import {InfraService} from './services/infra.service';
import {ActivityOfEntityService} from './services/activity.of.entity.service';
import {RoomService} from './services/room.service';
import {MedicationService} from './services/medication.service';
import {ProgressNoteService} from './services/progress.note.service';
import {SupportPlanService} from './services/support.plan.service';
import {RoleService} from './services/role.service';
import {ContactService} from './services/contact.service';
import {AddressService} from './services/address.service';
import {ResidentTransferServices} from './services/resident.transfer.services';
import {ResidentPharmacyService} from './services/resident.pharmacy.service';
import {PerformanceReviewService} from './services/performance.review.service';
import {MeetingService} from './services/meeting.service';
import {ChartService} from './services/chart.service';
import {LetterService} from './services/letter.service';
import {ProspectiveResidentService} from './services/prospective.resident.service';
import {RentIncreaseLetterService} from './services/rent.increase.letter.service';
import {SupportPlanCategoryService} from './services/support.plan.category.service';
import {SupportPlanEntryService} from './services/support.plan.entry.service';
import { FileUploadService } from './services/file-upload.service';
import { SubscriptionService } from './services/subscription.service';
import { SupportPlanTemplateService } from './services/support.plan.template.service';
import { ConditionService } from './services/agreement.service';
import { ForgetPasswordService } from './services/forgetPassword.services';
import { RosterService} from './services/roster.service';


const socialLinks = [
 /* {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'twitter',
  },*/
];

const DATA_SERVICES = [
  { provide: UserService},
  {provide: AuthGuard},
  {provide: ResidentService},
  {provide: OrganizationService},
  {provide: SettingsService},
  {provide: AgreementService},
  {provide: AppointmentService},
  {provide: BookingService},
  {provide: ComplaintService},
  {provide: DashboardService},
  {provide: DoctorService},
  {provide: HandoverService},
  {provide: IncidentService},
  {provide: InvoiceService},
  {provide: MessageService},
  {provide: InfraService},
  {provide: ActivityOfEntityService},
  {provide: RoomService},
  {provide: MedicationService},
  {provide: ProgressNoteService},
  {provide: SupportPlanService},
  {provide: RoleService},
  {provide: ContactService},
  {provide: AddressService},
  {provide: ResidentTransferServices},
  {provide: ResidentPharmacyService},
  {provide: PerformanceReviewService},
  {provide: MeetingService},
  {provide: ChartService},
  {provide: LetterService},
  {provide: ProspectiveResidentService},
  {provide: RentIncreaseLetterService},
  {provide: SupportPlanCategoryService},
  {provide: SupportPlanEntryService},
  {provide: FileUploadService},
  {provide: SubscriptionService},
  {provide: SupportPlanTemplateService},
  {provide: ConditionService},
  {provide: ForgetPasswordService},
  {provide: RosterService},
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  // ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: `${environment.api_url}/api/auth/`,
        token: {
          class: NbAuthJWTToken,
          key: 'token',
        },
      }),
    ],
    forms: {
      login: {
        socialLinks: socialLinks,
      },
      register: {
        socialLinks: socialLinks,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  { provide: HTTP_INTERCEPTORS, useClass: JwtTokenInterceptor, multi: true },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}

export function toFormData<T>( formValue: T ) {
  const formData = new FormData();

  for ( const key of Object.keys(formValue) ) {
    const value = formValue[key];
    formData.append(key, value);
  }

  return formData;
}
