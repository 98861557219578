import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';
import {RentIncreaseLetter} from '../data/rent.increase.letter';

@Injectable()
export class RentIncreaseLetterService {

  constructor(private httpClient: HttpClient) {
  }


  findAll() {
    return this.httpClient.get<PagedResponse<RentIncreaseLetter>>(`${environment.api_url}/api/rent-increase-letter`);
  }

  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/rent-increase-letter/${id}`);
  }

  findById(id: string) {
    return this.httpClient.get<RentIncreaseLetter>(`${environment.api_url}/api/rent-increase-letter/${id}`);
  }

  newLetter(value: any) {
    return this.httpClient.post<RentIncreaseLetter>(`${environment.api_url}/api/rent-increase-letter`, value);
  }

  updateLetter(id: string, value: any) {
    return this.httpClient.put<RentIncreaseLetter>(`${environment.api_url}/api/rent-increase-letter/${id}`, value);
  }
  findByResidentId(id: string) {
    return this.httpClient.get<PagedResponse<RentIncreaseLetter>>(`${environment.api_url}/api/rent-increase-letter?residentId=${id}`);
  }

  addHistory(spId: string, payload: any) {
    // return this.httpClient.post<SupportPlan>(`${environment.api_url}/api/support-plans/${spId}/reviews`, payload);
    return this.httpClient.post<RentIncreaseLetter>(`${environment.api_url}/api/rent-increase-letter/${spId}/reviews`, payload);
  }

  findReviewsById(spId: string) {
    return this.httpClient.get<any[]>(`${environment.api_url}/api/rent-increase-letter/${spId}/reviews`);
  }
}
