import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';
import {Doctor} from '../../pages/doctors/doctor';
import {CreateContactInput} from '../../pages/contact/create.contact.input';
import {UpdateContactInput} from '../data/update.contact.input';

export class CreateDoctorInput {
  name: string;
  specialization: string;
  profilePictureId: string;
  contact: CreateContactInput;
}

export class UpdateDoctorInput {
  name: string;
  specialization: string;
  profilePictureId: string;
  contact: UpdateContactInput;
}

@Injectable()
export class DoctorService {

  constructor(private httpClient: HttpClient) {
  }

  findAll() {
   return this.httpClient.get<PagedResponse<Doctor>>(`${environment.api_url}/api/doctors`);
  }

  newDoctor(value: CreateDoctorInput) {
    return this.httpClient.post<Doctor>(`${environment.api_url}/api/doctors`, value);
  }

  findById(id: string) {
    return this.httpClient.get<Doctor>(`${environment.api_url}/api/doctors/${id}`);
  }

  deleteDoc(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/doctors/${id}`);
  }

  updateDoc(id: string, updInput: UpdateDoctorInput) {
   return this.httpClient.put(`${environment.api_url}/api/doctors/${id}`, updInput);
  }

  searchDoctors(param: any) {
    return this.httpClient.get<PagedResponse<Doctor>>(`${environment.api_url}/api/doctors?name=${param}`);
  }
}
