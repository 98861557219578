export class MessageCountData {
  constructor() {
    this.unreadMessageCount = 0;
    this.totalMessageCount = 0;
    this.sentItemCount = 0;
  }
  unreadMessageCount: number;
  totalMessageCount: number;
  sentItemCount: number;
}
