import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PagedResponse } from '../data/paged.response';
import { Role } from '../data/role';
import * as qs from 'querystring';


@Injectable()
export class RoleService {
  private rid: string;
  constructor(private httpClient: HttpClient) {
  }

  deleteRole(id: any) {
    return this.httpClient.delete(`${environment.api_url}/api/roles/${id}`);
  }
  SearchRole(value) {
    return this.httpClient.get<PagedResponse<Role>>(`${environment.api_url}/api/roles?${qs.encode(value)}`);
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Role>>(`${environment.api_url}/api/roles`);
  }

  newRole(value: any) {
    return this.httpClient.post(`${environment.api_url}/api/roles`, value);
  }
  findAccess(id: any) {
    return this.httpClient.get(`${environment.api_url}/api/roles/${id}/access`);
  }
  rolePermission(roleId, Input) {
    return this.httpClient.post<any>(`${environment.api_url}/api/roles/${roleId}/access`, Input);
  }
  rolePermissionUpdate(roleId, Input) {
    return this.httpClient.put<any>(`${environment.api_url}/api/roles/${roleId}/access`, Input);
  }
  listAllRoleAcess() {
    return this.httpClient.get(`${environment.api_url}/api/roles`);
  }
  listAllRoleBYID(id) {
    return this.httpClient.get(`${environment.api_url}/api/roles?id=${id}`);
  }


  public get roleId() {
    return this.rid;
  }

  public set roleId(val) {
    this.rid = val;
  }
}
