import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {PagedResponse} from '../data/paged.response';
import {SupportPlanCategory} from '../data/support.plan.category';

@Injectable()
export class SupportPlanCategoryService {
  constructor(private httpClient: HttpClient) {
  }

  findById(id: string) {
    return this.httpClient.get<SupportPlanCategory>(`${environment.api_url}/api/support-plan-categories/${id}`);
  }

  newPlan(formValue: any) {
    return this.httpClient.post(`${environment.api_url}/api/support-plan-categories`, formValue);
  }

  updatePlan(spId: string, value: any) {
    return this.httpClient.put(`${environment.api_url}/api/support-plan-categories/${spId}`, value);
  }

  findAll() {
    // return this.httpClient.get<PagedResponse<SupportPlanCategory>>(`${environment.api_url}/api/support-plan-categories`);
    return this.httpClient.get<PagedResponse<SupportPlanCategory>>(`${environment.api_url}/api/support-plans`);
  }

  deletePlan(id: any) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/support-plan-categories/${id}`);
  }

}
