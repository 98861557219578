
<div class="containerr">


    <h4 class="nosubs">Terms And Conditions</h4>
    <p class="sp">By purchasing an Annual License to use the SRSPro web application software, the following conditions, rights and responsibilities are required to be outlined and understood.</p>
    <div class="Terms">
        <div class="scrollable-div">
          <p>1.This license is fixed term subscription for a period of 12 months, after which the licensee has the option to cancel the subscription. The fee is required to be paid at the start of the 12 months, a process which will be set by default to renew the payment debit/credit card on the required date via an online payments system. The annual fee is based on the size of your business by beds/clients, this determines the required resources for the amount of data/usage to host and manage. Price is subject to increase along with CPI. This will be reviewed annually and may not always be implemented as it will only be required where expenses such as online hosting or programmer wages may increase.</p>
          <p>2.Refund policy-any early cancellation of an annual license subscription must be in writing with 30 days' notice to the licensor via www.srspro.com.au contact us form or email. Refunds will be calculated thereafter the 30 days of the notice to the end of the annual subscription, this ensures administration costings for setup and termination.</p>
          <p>3.If the licensee's subscription expires then the log in screen will take the user to a page for payment. If the licensee does not wish to continue the subscription, the termination process will begin once confirmed with the licensee, with the database no longer being hosted by the server, at which time a full export can be made available to the licensee at request of the licensor. Suspending a subscription at the request of the licensee will not be an option due to the ongoing costings incurred to the licensor.</p>
          <p>4.This license is for use with one intended site/facility only, the user/purchaser does not own the software and therefore does not have any rights to its code. Although the owner/development team are the only party to make decisions of any changes, based on what is achievable, required and best for all users, the licensee is encouraged to make any suggestions for improvements or fixes. SRSPro code remains the property of the developer and copyright protected owner- Michel Bhugon</p>
          <p>5.Technical support will be provided in relation to any program code error or failure of the software to function. Technical support on all other areas of concern is deemed out of the scope of the software and therefore not the responsibility of the developer. Some examples of other areas of concern may include but not restricted to issues caused by: other software, operating systems, unreliable hardware, viruses, physical connections, fragmentation, backing up procedures, user error or data corruption. Of which may cause issues such as: the inability to print, to start the computer, computer freezing, network communication failure, data loss, network initial setup, computer initial setup, computer operating speed etc.</p>
          <p>6.It is the responsibility of the SRS to maintain the software data to the required level of regulation and ensure compliance is achieved. SRSPRo is merely a guideline and does not guarantee regulation compliance, the data entered as well as the facilities policies and practices which reflect the data entered needs to meet the standards.</p>
          <p>7.It is the responsibility of the SRS to ensure that the computers, laptops and other devices used are reliable and capable of running the SRSPro Software. This includes access to a fast and reliable internet connection.</p>
          <p>8.As well as time saving and compliance benefits, the purpose of SRSPro software is to access data faster than can be done with a physical filing system but it is not the intention of the SRSPro software to replace a physical filing system. The software is designed to also print out all documents to file so as to have a true dated copy in case of data loss and that can be accessed at times when technology or devices fail or is inaccessible to reference or audit. This also ensures the documentation is always classed as onsite. SRSPro runs on an online server which although may be reliable and mitigate against data loss, it must be assumed that restoring from any available backups may not always retrieve the required data. It is therefore the responsibility of the SRS to printout and keep a hard copy of all documents. Any data loss that may occur can be due to many factors including but not restricted to: communication interruption, user error, code error or unauthorised access. Technical support will be provided for data loss at fault of program code but in the case of failure to restore data it is the responsibility of the purchaser to have all documentation printed out acting as a physical backup and therefore the developer will not be held liable for data loss.</p>
          <p>9.It is the responsibility of the licensee to enforce privacy and confidentiality policies on how data is viewed, managed and shared. You must notify us immediately if you become aware of any unauthorized use or access to any accounts. You are responsible for any actions taken through the use of your credentials and your users’ credentials, except for actions taken after you have told us that your account or credentials have been compromised. You must ensure all users are aware to use adequate secure passwords, not to share their credentials with any other persons and to log out of the software when not present to ensure no unauthorized use. Being a web application software offsite logins are allowed by users and therefore is the responsibility of the licensee to ensure privacy and confidentiality policies are in place</p>
          <!-- Add more content here -->
        </div>
      </div>
    <button style="width: 150px;"  class="btn   w-40 button__text"
    (click)="onClickPayment()">Agree and continue</button>

    <div  >

        <img src="../../../../assets/images/srs_wht.png"  alt="Logo" id="logoImage">
    </div>
</div>